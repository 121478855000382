import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import FestifHelmet from '../components/Helmet/FestifHelmet'
import Wolflayout from '../components/wolflayout'
import Banner from '../components/NewTheme/Ui/Banner'
import ColorButton from '../components/Ui/ColorButton'
import SectionTitle from '../components/Ui/SectionTitle'
import { getHoursWith2023Format } from '../utils/formattedDate'
import { getFormattedDayOfWeek } from '../components/Utils/DateTime'

const Event = ({ pageContext }) => {
  let {
    title,
    description,
    stage,
    main_image: mainImage,
    start_time: startDate,
    free: isFree
  } = pageContext.post.data

  title = title?.text
  description = description?.text
  const image = getImage(mainImage)

  const stageName = stage?.document?.data?.title?.text
  // const stageSlug = stage?.document?.slug
  // const appearance = pageContext.appearance
  const pageTitle = 'Évènements'
  return (
    <Wolflayout title={pageTitle}>
      <FestifHelmet htmlAttributes={{ lang: 'fr' }}>
        <script src="https://lepointdevente.com/plugins/cart/js?locale=fr_CA&amp;ga=UA-32197713-2"></script>
      </FestifHelmet>
      <Banner title={pageTitle} />

      {/* BACK BUTTON */}
      {/* TODO: This is not working if there is no history */}
      <ColorButton text="← Retour à la liste" isPink={true} className="hover:bg-blue-wolf2025 mx-auto w-64 !mt-9 md:!mt-0" onClick={() => {
          window.history.back();
      }} />

      <div className="md:w-1/2 w-full mx-auto pt-12 px-2 md:px-16">
        <section className='border-4 border-purple-wolf2025 rounded-3xl overflow-hidden'>

          {/* YELLOW SECTION */}
          <div className='bg-yellow-wolf2025 text-center py-4 border-b-4 border-purple-wolf2025'>
            <h2 className='font-obviously font-black text-purple-wolf2025 uppercase text-3xl'>{title ? title : ''}</h2>
          </div>

          {/* PHOTO */}
          <div className="relative">
            <div className="w-full mx-0 px-0">
              <div className="relative text-center">
                {image && (
                  <GatsbyImage
                    className=""
                    image={image}
                    alt={image.alt ? image.alt : ''} />
                )}
              </div>
            </div>
          </div>
          
          {/* SCHEDULE */}
          <div className="pb-4 flex-col text-center max-w-full mx-auto px-auto w-full bg-purple-wolf2025">
            <div className='mx-0 w-full pt-4'>
              <SectionTitle className={'font-obviously font-black uppercase text-yellow-wolf2024 tracking-wide text-center text-2xl'}>
                {getFormattedDayOfWeek(new Date(startDate))}
              </SectionTitle>
              <div className="font-obviously font-medium italic text-yellow-wolf2024 tracking-wider text-md mb-1">
                {title
                  ? (
                  <a href="/infos/carte#info_content" >
                    {title.text}
                  </a>
                    )
                  : null}
              </div>
              <div className="font-obviously font-medium italic text-white text-md text-center tracking-tight">
                <ul className="list-none mb-0 py-1">
                  <span className="">
                    {getHoursWith2023Format(new Date(startDate))}
                    {' • '}
                  </span>
                  <span>
                    {stageName}
                  </span>
                </ul>
              </div>
            </div>
          </div>

          {/* DESCRIPTION */}
          <div className='font-openSans font-light italic text-blue-wolf2025 bg-purple-wolf2025 text-center pb-6 pt-0'>
            <p className='px-16'>{description}</p>
          </div>

        </section>

        <div className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : 'w-36 mx-auto'}`}>
          {isFree
            ? <></>
            : <div className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : ''} my-12`}>
                <ColorButton text="Billets" href={getTicketURL()} externalLink={true} className="hover:bg-blue-wolf2025" />
              </div>
          }
        </div>
      </div>
    </Wolflayout>
  )
}

export default Event

